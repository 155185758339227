@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

[data-sonner-toaster][data-theme='light'] {
	--normal-bg: hsla(237, 80%, 60%, 1);
	--normal-border: hsla(237, 80%, 60%, 1);
	--normal-text: hsl(0, 0%, 100%);

	--success-bg: hsla(122, 77%, 41%, 1);
	--success-border: hsla(122, 77%, 41%, 1);
	--success-text: hsl(0, 0%, 100%);
	--error-bg: hsla(349, 80%, 60%, 1);
	--error-border: hsla(349, 80%, 60%, 1);
	--error-text: hsl(0, 0%, 100%);
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}

/* ===== Scrollbar CSS ===== */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

*::-webkit-scrollbar-track {
	background: hsla(236, 73%, 96%, 1);
}

*::-webkit-scrollbar-thumb {
	background-color: hsla(237, 80%, 60%, 1);
	border-radius: 100px;
}

html {
	scroll-behavior: smooth;
	height: 100%;
}

.blog-shadow-dreamy {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
		0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.fade-slide-out {
	animation: 1.5s fadeSlideOut 1s ease-in-out;
}

.fade-slide-left {
	animation: fadeSlideLeftOut 4s ease-in-out;
}

.fade-slide-right {
	animation: fadeSlideRightOut 4s ease-in-out;
}

.fade-slide-up {
	animation: fadeSlideUpOut 4s ease-in-out;
}

.fade-slide-down {
	animation: fadeSlideDownOut 4s ease-in-out;
}

@keyframes fadeSlideLeftOut {
	0% {
		opacity: 0;
		transform: translate(-60%, -50%);
	}
	5% {
		opacity: 1;
		transform: translate(-116%, -50%);
	}
	30% {
		opacity: 1;
		transform: translate(-116%, -50%);
	}
	90% {
		opacity: 1;
		transform: translate(-116%, -50%);
	}
	95% {
		opacity: 0;
		transform: translate(-60%, -50%);
	}
	100% {
		opacity: 0;
		transform: translate(-60%, -50%);
	}
}

@keyframes fadeSlideRightOut {
	0% {
		opacity: 0;
		transform: translate(60%, -50%);
	}
	5% {
		opacity: 1;
		transform: translate(116%, -50%);
	}
	30% {
		opacity: 1;
		transform: translate(116%, -50%);
	}
	90% {
		opacity: 1;
		transform: translate(116%, -50%);
	}
	95% {
		opacity: 0;
		transform: translate(60%, -50%);
	}
	100% {
		opacity: 0;
		transform: translate(60%, -50%);
	}
}

@keyframes fadeSlideUpOut {
	0% {
		opacity: 0;
		transform: translateY(0%);
	}
	10% {
		opacity: 1;
		transform: translateY(-183%);
	}
	30% {
		opacity: 1;
		transform: translateY(-183%);
	}
	90% {
		opacity: 1;
		transform: translateY(-183%);
	}
	95% {
		opacity: 1;
		transform: translateY(0%);
	}
	100% {
		opacity: 0;
		transform: translateY(0%);
	}
}

@keyframes fadeSlideDownOut {
	0% {
		opacity: 0;
		transform: translateY(0%);
	}
	10% {
		opacity: 1;
		transform: translateY(120%);
	}
	30% {
		opacity: 1;
		transform: translateY(120%);
	}
	90% {
		opacity: 1;
		transform: translateY(120%);
	}
	95% {
		opacity: 1;
		transform: translateY(0%);
	}
	100% {
		opacity: 0;
		transform: translateY(0%);
	}
}

@keyframes fadeSlideOut {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(70%);
	}
}

@keyframes dialog-overlay-hide {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes dialog-content-show {
	from {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.95);
	}
	to {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}
}

@keyframes dialog-content-hide {
	from {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}
	to {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.95);
	}
}

@media print {
	html {
		font-size: 12px;
	}
	body {
		margin-bottom: 60px;
		margin-top: 60px;
	}
	section,
	article {
		page-break-before: auto;
	}
}

/* ----------- */
/* Day Picker  */
/* ----------- */

.rdp-tbody .rdp-row td button {
	margin: 0 auto;
}

@media (max-width: 1024px) {
	.rdp {
		--rdp-cell-size: 35px !important;
		--rdp-caption-font-size: 14px !important;
		margin: 0 !important;
	}

	.rdp table {
		font-size: 14px !important;
	}

	.rdp-button {
		max-width: none !important;
		width: 100% !important;
	}
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
